<template>
  <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary"
              class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>Editar producto</h4>
      <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
      <div class="p-6">
        <!--Image-->
        <div class="flex -mx-2">
          <div class="w-1/2 p-2">
            <!-- Image 1 -->
            <template v-if="product.image1">

              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image1" alt="img" class="responsive">
              </div>

              <!-- Image upload Buttons -->
              <div class="modify-img flex justify-between mt-5">
                <input type="file" class="hidden" ref="changeImgInput1" @change="updateCurrImg1"
                       accept="image/*">
                <span style="cursor: pointer" class="ml-2" type="flat"
                      @click="$refs.changeImgInput1.click()">Cambiar
                                </span>
                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                      @click="deleteImage1">Eliminar</span>
              </div>
            </template>
          </div>
          <div class="w-1/2 p-2">
            <!-- Image 2 -->
            <template v-if="product.image2">

              <!-- Image Container -->
              <div class="img-container flex items-center justify-center">
                <img :src="product.image2" alt="img" class="responsive">
              </div>

              <!-- Image upload Buttons -->
              <div class="modify-img flex justify-between mt-5">
                <input type="file" class="hidden" ref="updateImgInput2" @change="updateCurrImg2"
                       accept="image/*">
                <span style="cursor: pointer" class="ml-2" type="flat"
                      @click="$refs.updateImgInput2.click()">Cambiar
                                </span>
                <span style="cursor: pointer" class="mr-2" type="flat" color="#999"
                      @click="deleteImage2">Eliminar</span>
              </div>
            </template>
          </div>
        </div>
        <div class="flex -mx-2">
          <!-- Upload image 1 -->
          <div class="upload-img mt-5 w-1/2 p-2" v-if="!product.image1">
            <input type="file" class="hidden" ref="uploadImgInput1" @change="updateCurrImg1"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput1.click()">Primera imagen</vs-button>
          </div>

          <!-- Upload image 2-->
          <div class="upload-img mt-5  w-1/2 p-2" v-if="!product.image2">
            <input type="file" class="hidden" ref="uploadImgInput2" @change="updateCurrImg2"
                   accept="image/*">
            <vs-button style="width: 100%" @click="$refs.uploadImgInput2.click()">Segunda imagen</vs-button>
          </div>
        </div>
        <!--End-->

        <!-- Name -->
        <vs-input @input="setSlug" @blur="product.name= trimString(product.name)" label="Nombre"
                  v-model="product.name"
                  class="mt-5 w-full" name="name"
                  v-validate="'required|min:3|max:150'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('name')">{{ errors.first('name') }}</span>
        <!--End-->

        <!-- SKU -->
        <vs-input @blur="product.sku= trimString(product.sku)" label="SKU" v-model="product.sku"
                  class="mt-5 w-full" name="sku"
                  v-validate="'required|min:3|max:50'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('sku')">{{ errors.first('sku') }}</span>
        <!--End-->

        <!-- Slug -->
        <vs-input @blur="product.slug= trimString(product.slug)" label="Slug"
                  v-model="product.slug"
                  class="mt-5 w-full" name="slug"
                  v-validate="'required|min:3'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('slug')">{{ errors.first('slug') }}</span>
        <!--End-->

        <!---Prices--->
        <div class="flex -mx-2">
          <!-- Normal price -->
          <div class="w-1/2 p-2">
            <vs-input type="number" label="Precio normal" v-model.number="product.normalPrice" class="w-full"
                      name="normalPrice"
                      v-validate="'required|min_value:0'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('normalPrice')">{{ errors.first('normalPrice') }}</span>
          </div>
          <div class="w-1/2 p-2">
            <!-- Price Discount -->
            <vs-input type="number" label="Precio con descuento" v-model.number="product.priceDiscount"
                      class="w-full"
                      name="priceDiscount"
                      v-validate="'required|min_value:0'"/>
            <span class="text-danger text-sm"
                  v-show="errors.has('priceDiscount')">{{ errors.first('priceDiscount') }}</span>
          </div>
        </div>
        <!--End-->

        <!-- Description -->
        <vs-textarea @blur="product.description= trimString(product.description)" label="Descripción"
                     v-model="product.description" class="mt-5 w-full"
                     name="description"
                     rows="5"
                     v-validate="'min:5|max:1000'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('description')">{{ errors.first('description') }}</span>
        <!--End-->

        <!-- Categories -->
        <div>
          <label class="vs-select--label" for="categories">Selecciona una categoria</label>
          <v-select name="category" id="categories" label="name" :options="categories" v-model="product.category"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" v-validate="'required'"/>
          <span class="text-danger text-sm"
                v-show="errors.has('category')">{{ errors.first('category') }}</span>
        </div>
        <!--End-->

        <!-- Brands -->
        <div class="mt-5">
          <label class="text-sm opacity-75">Marca</label>
          <v-select label="name" :options="brands" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="product.brand" class="mb-4 md:mb-0" name="brand" v-validate="'required'"/>

          <span class="text-danger text-sm"
                v-show="errors.has('brand')">{{ errors.first('brand') }}</span>
        </div>
        <!--End-->

        <!-- Stock -->
        <vs-input label="Stock"
                  v-model.number="product.stock"
                  class="mt-5 w-full" name="position"
                  v-validate="'required|min_value:0'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('stock')">{{ errors.first('stock') }}</span>

        <!-- Weight -->
        <vs-input @input="setSlug" label="Peso en gramos"
                  v-model.number="product.weight"
                  class="mt-5 w-full" name="weight"
                  v-validate="'required|min_value:0'"/>
        <span class="text-danger text-sm"
              v-show="errors.has('weight')">{{ errors.first('weight') }}</span>
        <!--End-->

        <!-- Sections -->
        <div class="mt-5">
          <label class="vs-select--label" for="categories">Secciones</label>
          <v-select multiple id="sections" label="name" :options="sections" v-model="product.sections"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'" name="sections"/>
        </div>
        <!--End-->
      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button class="mr-6" @click="submitData" :disabled="!isFormValid || progress">Modificar</vs-button>
      <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">Cancelar</vs-button>
      <div v-if="progress" style="margin-top: 1rem; width: 100%">
        <vs-progress indeterminate color="primary"></vs-progress>
      </div>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import trimString from '../../mixins/trimString'
import vSelect from 'vue-select'

let _ = require('lodash')

const firebase = require('firebase')
// Required for side-effects
require('firebase/firestore')
let db = firebase.firestore()
export default {
  name: 'UpdateProducts',
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {
      }
    },
    productsAll: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    brands: {
      type: Array,
      required: true
    }
  },
  components: {
    VuePerfectScrollbar,
    vSelect
  },
  mixins: [trimString],
  data () {
    return {
      product: {
        priceDiscount: 0,
        normalPrice: 0,
        isPack: 'false',
        position: 0
      },

      newImage1: null,
      newImage2: null,
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      },
      progress: false,
      sections: ['ofertas', 'black-friday']
    }
  },
  watch: {
    isSidebarActive (val) {
      if (val) {
        // Related and selected products
        // End
        if (!this.data.quantityProducts) {
          this.data.quantityProducts = 1
        }
        if (!this.data.quantityLimit) {
          this.data.quantityLimit = 1
        }
        if (!this.data.productsIncluded) {
          this.data.productsIncluded = []
        }
        this.product = _.cloneDeep(this.data)
        // Validating if there is any image
        if (!this.product.image1)
          this.$set(this.product, 'image1', null)
        if (!this.product.image2)
          this.$set(this.product, 'image2', null)
        // End
        this.newImage1 = null
        this.newImage2 = null
        this.$validator.reset()
      }
    },
    product: {
      handler () {
        if (this.product.image1 === null) {
          this.newImage1 = null
        }
        if (this.product.image2 === null) {
          this.newImage2 = null
        }
      },
      deep: true
    }
  },
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          this.product = {}
        }
      }
    },
    isFormValid () {
      return !this.errors.any()
    },
    scrollbarTag () {
      return this.$store.getters.scrollbarTag
    }
  },
  methods: {
    submitData () {
      this.$validator.validateAll().then(async result => {
        if (result) {
          this.progress = true
          if (this.newImage1 != null) {
            this.product.image1 = await this.submitImages(this.newImage1)
          }
          if (this.newImage2 != null) {
            this.product.image2 = await this.submitImages(this.newImage2)
          }
          // Update product
          let productRef = db.collection('products').doc(this.product.id)

          //Clone to remove the id
          let cloneProduct = Object.assign({}, this.product)
          delete cloneProduct.id
          delete cloneProduct.createdAt

          // Select category
          cloneProduct.categoryId = cloneProduct.category.id
          cloneProduct.categoryName = cloneProduct.category.name

          productRef.update({
            ...cloneProduct,
            uid: firebase.auth().currentUser.uid,
            updatedAt: firebase.firestore.FieldValue.serverTimestamp()
          }).then(async () => {
            cloneProduct.id = this.product.id
            this.$store.dispatch('products/updateProduct', { product: cloneProduct })
            this.progress = false
            this.$vs.notify({
              color: 'success',
              title: 'Producto',
              text: 'Producto modificado correctamente.'
            })
            this.product = {}
            this.newImage1 = null
            this.newImage2 = null
            this.$emit('closeSidebar')
          }).catch((e) => {
            console.log(e)
            this.progress = false
            this.$vs.notify({
              color: 'warning',
              title: '¡Oh no!',
              text: 'Hubo un error.'
            })
          })
        }
      })
    },
    submitImages (image) {
      return new Promise((resolve, reject) => {
        let imageName = Math.random().toString(36).substr(1, 20)
        let fileExtension = '.' + image.name.split('.').pop()
        let imageNameFinal = imageName + fileExtension

        let storageRef = firebase.storage().ref(`products/${imageNameFinal}`)
        storageRef.put(image)
            .then((snapshot) => {
              snapshot.ref.getDownloadURL().then((url) => {
                resolve(url)
              })
            })
            .catch((error) => {
              reject(error)
            })
      })
    },
    updateCurrImg1 (input) {
      console.log(input)
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.product.image1 = e.target.result
          this.newImage1 = input.target.files[0]
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    updateCurrImg2 (input) {
      if (input.target.files && input.target.files[0]) {
        const reader = new FileReader()
        reader.onload = e => {
          this.product.image2 = e.target.result
          this.newImage2 = input.target.files[0]
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    deleteImage1 () {
      this.product.image1 = null
      this.newImage1 = null
    },
    deleteImage2 () {
      this.product.image2 = null
      this.newImage2 = null
    },
    setSlug () {
      this.product.slug = this.getSlug(this.product.name)
    },
    getSlug (name) {
      let text = name.trim().toLowerCase().replace(/ /g, '-').replace(/[-]+/g, '-').replace(/[^\w-]+/g, '')
      return text
    }
  }
}
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 650px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
