<template>
  <div id="data-list-list-view" class="data-list-container">
    <div v-if="initProgress" class="w-100 mt-5 mb-5">
      <vs-progress indeterminate color="primary"></vs-progress>
    </div>

    <vs-prompt title="Exportar a Excel" class="export-options" @cancle="clearFields" @accept="exportToExcel"
               accept-text="Exportar" cancel-text="Cancelar" @close="clearFields" :active.sync="activePrompt">
      <vs-input v-model="fileName" placeholder="Ingresa el nombre del archivo..." class="w-full"/>
      <v-select v-model="selectedFormat" :options="formats" class="my-4"/>
      <div class="flex">
        <span class="mr-4">Auto ajustar columna:</span>
        <vs-switch v-model="cellAutoWidth">Auto ajustar columna</vs-switch>
      </div>
    </vs-prompt>

    <vx-card v-if="filter" ref="filterCard" title="Filtros" class="user-list-filters mb-8" collapse-action
             refresh-content-action @refresh="resetFilters">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Filtrar por categoría padre</label>
          <v-select label="name" :options="categories" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="fatherCategoryFilter" class="mb-4 md:mb-0"/>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Filtrar por subcategoria</label>
          <v-select label="name" :options="allCategories" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="categoryFilter" class="mb-4 md:mb-0"/>
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Filtrar por stock</label>
          <v-select :options="stocksOptions" :clearable="false"
                    :dir="$vs.rtl ? 'rtl' : 'ltr'"
                    v-model="stockFilter" class="mb-4 md:mb-0"/>
        </div>
      </div>
    </vx-card>

    <new :brands="brands" :features="features" :categories="allCategories" :productsAll="productsAll"
         :isSidebarActive="newSidebar"
         @closeSidebar="toggleNewSidebar"
         :data="sidebarData"/>
    <update :brands="brands" :categories="allCategories" :productsAll="productsAll" :isSidebarActive="updateSidebar"
            @closeSidebar="toggleUpdateSidebar"
            :data="sidebarData"/>

    <vs-table v-model="selected" ref="table" pagination :max-items="itemsPerPage" search
              :data="productsPre">
      <div slot="header" class="flex flex-wrap-reverse items-center flex-grow justify-between">

        <div class="flex flex-wrap-reverse items-center data-list-btn-container">
          <!-- Action - dropnow -->
          <vs-dropdown vs-trigger-click class="dd-actions cursor-pointer mr-4">

            <div
                class="p-4 shadow-drop rounded-lg d-theme-dark-bg cursor-pointer flex items-center justify-center text-lg font-medium w-32 w-full">
              <span class="mr-2">Acciones</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item @click="editData(selected)">
                            <span class="flex items-center">
                              <feather-icon icon="EditIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Editar</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="filterData">
                            <span class="flex items-center">
                              <feather-icon icon="FilterIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Filtrar</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="activePrompt=true">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="exportToExcelFB">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar FB</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="exportToExcelGM">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Exportar GM</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="$router.push({name: 'products-import'})">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Actualización masiva</span>
                            </span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="syncAlgolia">
                            <span class="flex items-center">
                              <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2"/>
                              <span>Sinc. buscador</span>
                            </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
          <!-- Add new -->
          <vs-button :disabled="initProgress" color="danger" type="border" class="mr-6" @click="addNewData">
            Agregar
          </vs-button>
        </div>
        <!-- Items per page -->
        <vs-dropdown vs-trigger-click class="cursor-pointer mb-4 mr-4 items-per-page-handler">
          <div
              class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
            <span class="mr-2">{{
                currentPage * itemsPerPage - (itemsPerPage - 1)
              }} - {{
                products.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : products.length
              }} de {{
                queriedItems
              }}</span>
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4"/>
          </div>
          <vs-dropdown-menu>

            <vs-dropdown-item @click="itemsPerPage=10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=20">
              <span>20</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=30">
              <span>30</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage=40">
              <span>40</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th sort-key="sku">SKU</vs-th>
        <vs-th sort-key="name">Nombre</vs-th>
        <vs-th sort-key="category">Categoría</vs-th>
        <vs-th sort-key="brand">Marca</vs-th>
        <vs-th sort-key="stock">Stock total</vs-th>
        <vs-th sort-key="normalPrice">Precio Normal</vs-th>
        <vs-th sort-key="priceDiscount">Precio Dcto.</vs-th>
        <vs-th>Destacado</vs-th>
        <vs-th>Oferta</vs-th>
        <vs-th>Estado</vs-th>
        <vs-th>Acciones</vs-th>
      </template>

      <template slot-scope="{data}">
        <tbody>
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.sku }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.name }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.categoryName }}</p>
            <p></p>
          </vs-td>

          <vs-td>
            <p v-if="tr.brand" class="product-name font-medium truncate">{{ tr.brand.name }}</p>
            <p v-else></p>
          </vs-td>
          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.stock }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.normalPrice }}</p>
          </vs-td>

          <vs-td>
            <p class="product-name font-medium truncate">{{ tr.priceDiscount }}</p>
          </vs-td>

          <vs-td>
            <vs-switch @input="switchBestSeller(tr)" v-model="tr.bestSeller"/>
          </vs-td>

          <vs-td>
            <vs-switch @input="switchOffer(tr)" v-model="tr.offer"/>
          </vs-td>

          <vs-td>
            <vs-switch @input="switchState(tr)" v-model="tr.state"/>
          </vs-td>

          <vs-td class="whitespace-no-wrap">
            <feather-icon icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="editData(tr)"/>

            <feather-icon class="ml-2" icon="EyeIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current"
                          @click.stop="viewProduct(tr)"/>
          </vs-td>
        </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import New from './New'
import Update from './Update'
import vSelect from 'vue-select'

const firebase = require('firebase')
// Required for side-effects
require('firebase/firestore')
let db = firebase.firestore()
const _ = require('lodash')

export default {
  name: 'ListProducts',
  components: {
    New,
    Update,
    vSelect,
  },
  data () {
    return {
      itemsPerPage: 50,
      isMounted: false,
      productsPre: [],
      productsAll: [],
      initProgress: false,
      selected: null,
      filter: false,
      brands: [],
      features: [],
      stocks: [],

      //Filters
      categories: [],
      allCategories: [],
      stocksOptions: [
        { label: 'Todos', value: -1 },
        { label: 'Sin stock', value: 0 },
        { label: 'Por acabarse', value: 10 },
      ],
      fatherCategoryFilter: {},
      categoryFilter: {},
      stockFilter: {
        label: 'Todos',
        value: -1
      },
      //

      // Data Sidebar
      newSidebar: false,
      updateSidebar: false,
      featureSideBar: false,
      sidebarData: {},
      // Export
      fileName: '',
      formats: ['xlsx', 'csv', 'txt'],
      cellAutoWidth: true,
      selectedFormat: 'xlsx',
      headerTitle: ['Orden', 'ID', 'Categoría', 'Sub categoría', 'Marca', 'Nombre', 'Descripción', 'SKU', 'Precio normal', 'Precio con descuento', 'Estado', 'Stock'],
      headerVal: ['position', 'id', 'category', 'subCategory', 'brand', 'name', 'description', 'sku', 'normalPrice', 'priceDiscount', 'state', 'stock'],
      activePrompt: false
    }
  },
  async created () {
    try {
      this.initProgress = true
      //Get products
      await this.loadProducts()
      await this.loadCategories()
      await this.loadBrands()
      this.initProgress = false
    } catch (e) {
      console.log(e)
      this.$vs.notify({
        color: 'warning',
        title: '¡Oh no!',
        text: 'Hubo un error.'
      })
    } finally {
      this.initProgress = false
    }
  },
  mounted () {
    this.isMounted = true
  },
  computed: {
    products: {
      get () {
        return this.$store.state.products.products
      }
    },
    currentPage () {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    queriedItems () {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.products.length
    }
  },
  watch: {
    async fatherCategoryFilter (val) {
      if (val.id) {
        this.categoryFilter = {}
        this.initProgress = true
        this.productsPre = []
        await this.$store.dispatch('products/fetchProducts', { products: this.productsPre })
        db.collection('products')
            .where('category.father.id', '==', this.fatherCategoryFilter.id)
            .orderBy('position', 'desc').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let product = {
              id: doc.id,
              ...doc.data(),
              local1: 0,
              local2: 0
            }
            if (!product.hasVariants) {
              product.hasVariants = 'false'
            }
            // Validate if product is variant
            product.productId = product.productId ? product.productId : 'NN'
            if (product.productId === 'NN') {
              this.productsPre.push(product)
            }
          })
          this.initProgress = false
          this.$store.dispatch('products/fetchProducts', { products: this.productsPre })
        }).catch((e) => {
          console.log(e)
          this.initProgress = false
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        })
      }
    },
    async categoryFilter (val) {
      if (val.id) {
        this.fatherCategoryFilter = {}
        this.initProgress = true
        this.productsPre = []
        await this.$store.dispatch('products/fetchProducts', { products: this.productsPre })
        db.collection('products')
            .where('category.id', '==', this.categoryFilter.id)
            .orderBy('position', 'desc').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let product = {
              id: doc.id,
              ...doc.data(),
              local1: 0,
              local2: 0
            }
            if (!product.hasVariants) {
              product.hasVariants = 'false'
            }
            // Validate if product is variant
            product.productId = product.productId ? product.productId : 'NN'
            if (product.productId === 'NN') {
              this.productsPre.push(product)
            }
          })
          this.initProgress = false
          this.$store.dispatch('products/fetchProducts', { products: this.productsPre })
        }).catch((e) => {
          console.log(e)
          this.initProgress = false
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        })
      }
    },
    async stockFilter (val) {
      if (val.value !== -1) {
        this.initProgress = true
        this.productsPre = []
        await this.$store.dispatch('products/fetchProducts', { products: this.productsPre })
        db.collection('products')
            .where('stock', '<=', this.stockFilter.value).get()
            .then((querySnapshot) => {
              querySnapshot.forEach((doc) => {
                let product = {
                  id: doc.id,
                  ...doc.data(),
                  local1: 0,
                  local2: 0
                }
                if (!product.hasVariants) {
                  product.hasVariants = 'false'
                }
                // Validate if product is variant
                product.productId = product.productId ? product.productId : 'NN'
                if (product.productId === 'NN') {
                  this.productsPre.push(product)
                }
              })
              this.initProgress = false
              this.$store.dispatch('products/fetchProducts', { products: this.productsPre })
            }).catch(() => {
          this.initProgress = false
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
        })
      }
    }
  },
  methods: {
    addNewData () {
      this.sidebarData = {}
      this.newSidebar = true
    },
    editData (data) {
      if (data) {
        this.sidebarData = data
        this.toggleUpdateSidebar(true)
      }
    },
    filterData () {
      this.filter = !this.filter
    },
    toggleNewSidebar (val = false) {
      this.newSidebar = val
    },
    toggleUpdateSidebar (val = false) {
      this.updateSidebar = val
    },
    switchState (product) {
      let productRef = db.collection('products').doc(product.id)
      productRef.update({
        state: product.state
      }).then(() => {
        this.$store.dispatch('products/updateProduct', { product })
        this.$vs.notify({
          color: 'success',
          title: 'Producto',
          text: 'La modificación fue aplicada.'
        })
      }).catch(() => {
        this.$vs.notify({
          color: 'success',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      })
    },
    switchBestSeller (product) {
      let productRef = db.collection('products').doc(product.id)
      productRef.update({
        bestSeller: product.bestSeller
      }).then(() => {
        this.$store.dispatch('products/updateProduct', { product })
        this.$vs.notify({
          color: 'success',
          title: 'Producto',
          text: 'La modificación fue aplicada.'
        })
      }).catch(() => {
        this.$vs.notify({
          color: 'success',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      })
    },
    switchOffer (product) {
      let productRef = db.collection('products').doc(product.id)
      productRef.update({
        offer: product.offer
      }).then(() => {
        this.$store.dispatch('products/updateProduct', { product })
        this.$vs.notify({
          color: 'success',
          title: 'Producto',
          text: 'La modificación fue aplicada.'
        })
      }).catch(() => {
        this.$vs.notify({
          color: 'success',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      })
    },
    loadProducts () {
      this.productsPre = []
      this.$store.dispatch('products/fetchProducts', { products: this.productsPre })
      return new Promise((resolve, rejected) => {
        db.collection('products')
            .orderBy('state', 'desc')
            .orderBy('createdAt', 'desc')
            .get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let product = {
              id: doc.id,
              ...doc.data()
            }
            this.productsPre.push(product)

            let productAll = {
              id: product.id,
              name: product.name,
              image1: product.image1
            }
            this.productsAll.push(productAll)
          })
          resolve('ok')
        }).catch((error) => {
          rejected(error)
        })
      })
    },
    loadCategories () {
      return new Promise((resolve, rejected) => {
        db.collection('categories')
            .orderBy('createdAt', 'desc')
            .where('state', '==', true)
            .get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let category = {
              id: doc.id,
              ...doc.data()
            }
            if (category.father) {
              this.allCategories.push(category)
            }
            else {
              this.categories.push(category)
            }
          })
          resolve('ok')
        }).catch((error) => {
          rejected(error)
        })
      })
    },
    loadBrands () {
      return new Promise((resolve, rejected) => {
        db.collection('brands').orderBy('createdAt', 'desc').get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            let brand = {
              id: doc.id,
              ...doc.data()
            }
            this.brands.push(brand)
          })
          resolve('ok')
        }).catch((error) => {
          this.$vs.notify({
            color: 'warning',
            title: '¡Oh no!',
            text: 'Hubo un error.'
          })
          rejected(error)
        })
      })
    },
    async resetFilters () {
      this.initProgress = true
      await this.loadProducts()
      this.initProgress = false

      this.fatherCategoryFilter = {}
      this.categoryFilter = {}
      this.stockFilter = {
        label: 'Todos',
        value: -1
      }
      this.$refs.filterCard.removeRefreshAnimation()
    },
    exportToExcel () {
      this.initProgress = true
      import('@/vendor/Export2Excel').then(async (excel) => {
        const list = []
        const products = _.cloneDeep(this.productsPre)
        for await (const p of products) {
          let product = {
            position: p.position,
            id: p.id,
            name: p.name,
            description: p.description,
            sku: p.sku,
            priceDiscount: p.priceDiscount + '',
            normalPrice: p.normalPrice + '',
            state: p.state,
            category: p.category?.father ? p.category.father.name : 'NN',
            subCategory: p.category?.name,
            brand: p.brand?.name,
            stock: p.local1 + p.local2
          }
          list.push(product)
        }
        const data = this.formatJson(this.headerVal, list)
        excel.export_json_to_excel({
          header: this.headerTitle,
          data,
          filename: this.fileName,
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.clearFields()
        this.initProgress = false
      })
    },
    clearFields () {
      this.filename = ''
      this.cellAutoWidth = true
      this.selectedFormat = 'xlsx'
    },
    formatJson (filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        // Add col name which needs to be translated
        // if (j === 'timestamp') {
        //   return parseTime(v[j])
        // } else {
        //   return v[j]
        // }

        return v[j]
      }))
    },
    viewProduct (product) {
      window.open(
          'http://vialex.pe/products/' + this.getSlug(product.category.name) + '/' + product.slug,
          '_blank'
      )
    },
    /**
     * Sync products with algolia
     * @returns {Promise<void>}
     */
    async syncAlgolia () {
      try {
        this.initProgress = true
        await this.$http.post('https://us-central1-royal-tech-ee4b4.cloudfunctions.net/algolia-importProducts', {}, {
          headers: {
            'Authorization': 'Bearer 46ee87d8-5f2c-470f-91e7-3457189b9079'
          }
        })
        this.$vs.notify({
          color: 'success',
          title: 'Sincronización',
          text: 'Sincronización exitosa.'
        })
      } catch (e) {
        this.$vs.notify({
          color: 'warning',
          title: '¡Oh no!',
          text: 'Hubo un error.'
        })
      } finally {
        this.initProgress = false
      }
    },
    /**
     * Export excel to Facebook
     */
    exportToExcelFB () {
      import('@/vendor/Export2Excel').then(async excel => {
        this.initProgress = true
        const list = []
        for await (let p of this.productsPre) {
          if (p.category && p.state && p.brand) {
            const images = []
            if (p.image2) {
              images.push(p.image2)
            }
            if (p.image3) {
              images.push(p.image3)
            }
            if (p.image4) {
              images.push(p.image4)
            }
            if (p.image5) {
              images.push(p.image5)
            }
            let product = {
              id: p.sku,
              title: p.name,
              description: p.description,
              availability: p.stock > 0 ? 'in stock' : 'out of stock',
              condition: 'new',
              price: p.normalPrice + ' PEN',
              link: 'https://royal.com.pe/products/' + this.getSlug(p.category.name) + '/' + p.slug,
              image_link: p.image1,
              brand: 'Royal',
              sale_price: p.priceDiscount + ' PEN',
              visibility: p.state ? 'published' : 'hidden',
              additional_image_link: images.map((value) => {
                return value
              }).join(', ')
            }
            list.push(product)
          }
        }
        const headerVal = ['id', 'title', 'description', 'availability', 'condition', 'price', 'link', 'image_link', 'brand', 'sale_price', 'visibility', 'additional_image_link']
        const headerTitle = ['id', 'title', 'description', 'availability', 'condition', 'price', 'link', 'image_link', 'brand', 'sale_price', 'visibility', 'additional_image_link']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Exportacion para FB',
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.initProgress = false
      })
    },
    /**
     * Export excel to Facebook
     */
    exportToExcelGM () {
      import('@/vendor/Export2Excel').then(async excel => {
        this.initProgress = true
        const list = []
        for await (let p of this.productsPre) {
          let product = {
            id: p.sku,
            title: p.name,
            description: p.description,
            link: 'https://royal.com.pe/products/' + this.getSlug(p.category.name) + '/' + p.slug + '?father=' + p.category.father.slug,
            state: 'new',
            price: p.priceDiscount + ' PEN',
            availability: p.stock > 0 ? 'in_stock' : 'out_of_stock',
            image_link: p.image1,
            gtin: '',
            mpn: '',
            brand: 'Royal',
            gid: this.getGid(p.category)
          }
          list.push(product)
        }
        const headerVal = ['id', 'title', 'description', 'link', 'state', 'price', 'availability', 'image_link', 'gtin', 'mpn', 'brand', 'gid']
        const headerTitle = ['id', 'title', 'description', 'link', 'state', 'price', 'availability', 'image_link', 'gtin', 'mpn', 'brand', 'gid']
        const data = this.formatJson(headerVal, list)
        excel.export_json_to_excel({
          header: headerTitle,
          data,
          filename: 'Exportacion para GM',
          autoWidth: this.cellAutoWidth,
          bookType: this.selectedFormat
        })
        this.initProgress = false
      })
    },
    /**
     * Generate slug
     * @param name
     * @returns {string}
     */
    getSlug (name) {
      let text = name.trim().toLowerCase().replace(/ /g, '-').replace(/[-]+/g, '-').replace(/[^\w-]+/g, '')
      return text
    },
    getGid (category) {
      if (category.father.name === 'Televisores') {
        return 'Electronics > Video > Televisions'
      }
      else if (category.father.name === 'Bicicletas') {
        return 'Sporting Goods > Exercise & Fitness > Cardio > Cardio Machines > Exercise Bikes'
      }
      else if (category.father.name === 'Electrohogar') {
        return 'Home & Garden > Household Appliance Accessories'
      }
      else if (category.father.name === 'Audio y Video') {
        return 'Electronics > Audio'
      }
      else {
        return 'Business & Industrial'
      }
    }
  }
}
</script>

<style lang="scss">
#data-list-list-view {
  .vs-con-table {

    /*
      Below media-queries is fix for responsiveness of action buttons
      Note: If you change action buttons or layout of this page, Please remove below style
    */
    @media (max-width: 689px) {
      .vs-table--search {
        margin-left: 0;
        max-width: unset;
        width: 100%;

        .vs-table--search-input {
          width: 100%;
        }
      }
    }

    @media (max-width: 461px) {
      .items-per-page-handler {
        display: none;
      }
    }

    @media (max-width: 341px) {
      .data-list-btn-container {
        width: 100%;

        .dd-actions,
        .btn-add-new {
          width: 100%;
          margin-right: 0 !important;
        }
      }
    }

    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, .05);

        td {
          padding: 20px;

          &:first-child {
            border-top-left-radius: .5rem;
            border-bottom-left-radius: .5rem;
          }

          &:last-child {
            border-top-right-radius: .5rem;
            border-bottom-right-radius: .5rem;
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
